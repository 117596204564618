import { Nav } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";

import "./Navbar.css";
const logo = require("../../assets/cxl-logo.webp");
const twitter = require("../../assets/twitter.png");
const youtube = require("../../assets/youtube.png");
const linkedin = require("../../assets/linkedin.png");

function NavbarComponent() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  });
  return (
    <>
      <Navbar
        className={scroll ? "navbar-main py-3 scrolling" : "navbar-main py-3"}
        variant="dark"
        collapseOnSelect
        expand="lg"
      >
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt="CogXR Labs"
              src={String(logo)}
              height="30"
              className="logo-img d-inline-block ms-2 ms-sm-0"
            />
            {/* <span className="navbar-logo">CxL</span>{" "} */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto ms-lg-3 ms-xl-5 align-items-center">
              <Nav.Link
                href="#products"
                className="nav-item mx-2 px-lg-3 px-xl-5py-1"
              >
                Our Products
              </Nav.Link>
              <Nav.Link
                href="#about-us"
                className="nav-item mx-2 px-lg-3 px-xl-5py-1"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="#who-we-are"
                className="nav-item mx-2 px-lg-3 px-xl-5py-1"
              >
                Who We Are
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto align-items-center">
              <Nav.Link
                href=" https://twitter.com/cogxrlabs"
                id="twitter"
                target="_blank"
                className="nav-item mx-2 py-1"
                activeClassName="active"
              >
                <img
                  alt="twitter"
                  src={String(twitter)}
                  height="30"
                  className="d-inline-block "
                />{" "}
              </Nav.Link>

              <Nav.Link
                href=" https://www.linkedin.com/company/cogxrlabs/"
                id="linkedin"
                target="_blank"
                className="nav-item mx-2 py-1"
                activeClassName="active"
              >
                <img
                  alt="linkedin"
                  src={String(linkedin)}
                  height="30"
                  className="d-inline-block "
                />{" "}
              </Nav.Link>

              <Nav.Link
                href="https://www.youtube.com/channel/UCDL7aDkZWm840Qrjgoltchw"
                id="youtube"
                className="nav-item mx-2 py-1"
                activeClassName="active"
              >
                <img
                  alt="youtube"
                  id="youtube"
                  target="_blank"
                  src={String(youtube)}
                  height="30"
                  className="d-inline-block "
                />{" "}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
