import { useEffect, useRef, React } from "react";
import { Container } from "react-bootstrap";
import { gsap } from "gsap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./Products.css";

import pi1 from "../../assets/product-info-image-1.png";
import cxai1 from "../../assets/cxai1-1.webp";
import pi2 from "../../assets/product-info-image-2.png";
import cxxr1 from "../../assets/CxXR1.webp";
import pi3 from "../../assets/product-info-image-3.png";
import cxne1 from "../../assets/CxNEURO.webp";

gsap.registerPlugin(ScrollTrigger);

function ProductComponent() {
  const ProductRef = useRef();
  const MissionRef = useRef();
  const mm = gsap.matchMedia();
  useEffect(() => {
    const tlOneDesktop = gsap.timeline({
      delay: 0.5,
      scrollTrigger: {
        trigger: ProductRef.current,
        start: "center bottom", // position [trigger scroller]
        end: ProductRef.current.clientWidth * 2 + " center", // position [trigger scroller]
        onLeave: function (self) {
          self.disable();
          self.animation.progress(1);
        },
      },
    });

    const tlTwoDesktop = gsap.timeline({
      delay: 0.5,
      scrollTrigger: {
        trigger: MissionRef.current,
        start: "center bottom", // position [trigger scroller]
        end: MissionRef.current.clientWidth * 1.5 + " center", // position [trigger scroller]
        onLeave: function (self) {
          self.disable();
          self.animation.progress(1);
        },
      },
    });

    const tlTwoMobile = gsap.timeline({
      delay: 0.5,
      scrollTrigger: {
        trigger: MissionRef.current,
        start: "top+=20rem bottom", // position [trigger scroller]
        end: "top center", // position [trigger scroller]
        onLeave: function (self) {
          self.disable();
          self.animation.progress(1);
        },
      },
    });

    tlOneDesktop
      .fromTo(
        ".left",
        { xPercent: -20 },
        { xPercent: 0, duration: 1, stagger: 0.7 }
      )
      .fromTo(
        ".right",
        { xPercent: 20 },
        { xPercent: 0, duration: 1, stagger: 0.7 },
        0
      );

    mm.add(
      "min-width: 991px",
      tlTwoDesktop.fromTo(
        ".mv-up",
        { yPercent: 20 },
        { yPercent: 0, duration: 1 }
      )
    );
    mm.add(
      "min-width: 300px",
      tlTwoMobile.fromTo(
        ".mv-up",
        { yPercent: 10 },
        { yPercent: 0, duration: 1 }
      )
    );
  }, []);

  return (
    <>
      <a class="anchor" id="products"></a>
      <Container className="text-white">
        <h2 className="p-4 m-1" ref={ProductRef}>
          OUR PRODUCTS
        </h2>
        <div className="row p-3 product-row">
          <div className="product-info col-lg-6 left">
            <div className="cxai-image text-start pt-4 px-4">
              <img
                className="cxai-img"
                src={cxai1}
                alt="CxAI, the AI vertical of CogXR Labs"
              />
            </div>
            <div className="cxai-text text-justify px-4 py-4">
              CxAI is our AI vertical where we are building AI-based solutions
              for healthcare problems. Currently, we are working on two projects
              with our natively developed state-of-the-art classification and
              object-detection algorithms. The products are Oncarea and
              Digi-X-Care.
            </div>
            <div className="text-center px-4 pb-5">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Coming Soon!</Tooltip>}
                variant="light"
              >
                <button className="cxai-btn text-white px-3 py-2 mx-2 my-2">
                  Take me to Digi-X-Care{" "}
                  <i class="fa-solid fa-arrow-right px-1 text-white"></i>
                </button>
              </OverlayTrigger>
              <a href="https://oncarea.com/#home" target="_blank">
                <button className="cxai-btn text-white px-3 py-2 mx-2 my-2">
                  Take me to Oncarea{" "}
                  <i class="fa-solid fa-arrow-right px-1 text-white"></i>
                </button>
              </a>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center right">
            <img
              className="cxai-product-img"
              src={pi1}
              alt="CXAI illustration"
            />
          </div>
        </div>

        <div className=" row2 row p-3 product-row">
          <div className="product-info col-lg-6 right">
            <div className="cxai-image text-start p-4">
              <img
                className="cxai-img"
                src={cxxr1}
                alt="CXXR, the extended reality front of CogXR labs"
              />
            </div>
            <div className="cxai-text text-justify px-4 pb-4">
              Reality as we know it is going to be obsolete very soon. Join us
              in building the meta-verse for Healthcare 4.0 where real patient
              care meets with the accessibility of a virtual call. Not only
              that, we are building some of the best XR apps for multiple
              use-cases like Virtual Walkthroughs, Cultural Tourism, Meditation,
              Mental health and what not!
            </div>
            <div className="text-center px-4 pb-5">
              <a href="https://youtu.be/cwYZT9jje-o" target="_blank">
                <button className="cxai-btn text-white px-3 py-2 mx-2 my-2">
                  Virtual Walkthrough{" "}
                  <i class="fa-solid fa-arrow-right px-1 text-white"></i>
                </button>
              </a>
              <a href="https://youtu.be/J99wMdRAtJs" target="_blank">
                <button
                  className="cxai-btn text-white px-3 py-2 mx-2 my-2"
                  id="btn2"
                >
                  Wellness in VR{" "}
                  <i class="fa-solid fa-arrow-right px-1 text-white"></i>
                </button>
              </a>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center left">
            <img
              className="cxai-product-img"
              src={pi2}
              alt="CXXR illustration"
            />
          </div>
        </div>

        <div className="row p-3 product-row">
          <div className="product-info col-lg-6 left">
            <div className="cxai-image text-start pt-4">
              <img
                className="cxai-img"
                src={cxne1}
                id="cxneuro"
                alt="CxNeuro, the neurological "
              />
            </div>
            <div className="cxai-text px-4 py-4">
              At CxNeuro, we will directly work with the most complex machine in
              the universe, “human brain”. <br></br>
              Using brain waves and state-of-the-art AI, our aim is to provide
              assistance not only to patients but also to daily users. To that
              end, we will start with a cognitive state assessor to analyse the
              mental state during a task and provide feedback to improve the
              performance while being completely relaxed.
            </div>
            <div className="text-center buttons px-4 pb-5">
              <button className="cxai-btn text-white px-3 py-2">
                Coming Soon{" "}
                <i class="fa-solid fa-arrow-right px-1 text-white"></i>
              </button>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center right">
            <img className="cxai-product-img" src={pi3} alt="" />
          </div>
        </div>

        <section className="pt-5 mv-up" ref={MissionRef} id="about-us">
          <div className="row p-2 p-sm-5">
            <div className="col-lg-5">
              <div className="about-us mx-sm-3">
                <h2 className="text-center pt-5 text-underline">ABOUT US</h2>
                <p className="about-us-text p-5">
                  {" "}
                  CogXR Labs is a for-profit social enterprise with an aim to
                  commence Healthcare 4.0 in India. We are a confluence of
                  state-of-the-art cutting-edge disciplines including, but not
                  limited to, AI (Machine Learning and Deep Learning), Extended
                  Reality (Virtual, Augmented and Mixed), and Brain Computer
                  Interfaces.
                </p>
                <div className="abt-btn d-flex justify-content-end">
                  {/* <button className="cxai-btn text-white px-2 py-2 mb-5 me-5">
                    {" "}
                    Explore More{" "}
                    <i class="fa-solid fa-arrow-right px-1 text-white"></i>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="mis-vis p-5 col-lg-7 mt-4 mt-lg-0">
              <div className="mt-1">
                <h2 className="text-center mb-3 text-underline">MISSION</h2>
                Not only to solve critical problems the healthcare world is
                facing today, but also to build these solution with a human
                touch by amalgamation of human cognition in all our algorithms.
              </div>
              <div className="mt-2">
                <h2 className="text-center mb-3 text-underline">VISION</h2>
                Our vision is to introduce improved, transformational suite of
                products, providing patients with better, more value-added, and
                cost-effective healthcare services while improving the
                industry's efficacy and efficiency.
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default ProductComponent;
