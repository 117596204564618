import React from "react";
import Element from "react-scroll/modules/components/Element";
import "@fontsource/mulish";
import "./Footer.css";

import logo from "../../assets/cxl-logo.webp";

function FooterComponent() {
  return (
    <Element name={"support"}>
      <footer>
        <div className="text-white">
          <div className="row text-start">
            <div className="footer-div row gx-1 pb-4">
              <div className="footer-logo col-12 col-md-6 col-lg-8">
                <a href="#home">
                  <img className="logo mx-3" src={logo} alt="CogXR Labs"></img>
                  {/* <span className="navbar-logo">CxL</span> */}
                </a>
              </div>
              <div className="socials-container col-12 col-md-6 col-lg-4">
                <h5>Connect with us</h5>
                <span className="socials">
                  <a href="https://www.facebook.com/cogxrlabs" target="_blank">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCDL7aDkZWm840Qrjgoltchw"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href=" https://twitter.com/cogxrlabs" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/cogxrlabs/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/cogxrlabs/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 footer-about-wrapper gx-1">
              <div className="footer-widget footer-about">
                <h4>About</h4>
                <p id="about-text">
                  <strong>CxL or CogXR Labs Pvt. Ltd. </strong> is a for-profit
                  social enterprise with an aim to commence Healthcare 4.0 in
                  India. It is a confluence of state-of-the-art cutting-edge
                  technologies like Artificial Intelligence, Extended Reality
                  (Virtual, Augmented and Mixed), and Brain Computer Interfaces.
                </p>
              </div>
              <div className="footer-cp">
                <p className="copyrights">CogXR Labs Private Limited</p>
                <p className="terms">
                  <a
                    href="https://oncarea.com/terms-and-conditions-web.html"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>
                  <span style={{ color: "blue" }}> | </span>
                  <a
                    href="https://oncarea.com/privacy-policy-web.html"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 footer-contact">
              <div className="footer-widget ">
                <div className="row footer-contact-header">
                  <h4>CONTACT US</h4>
                  <p className="box">contact@cogxrlabs.com</p>
                </div>
                <div className="map" style={{ width: "100%" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6919.80483779104!2d77.8978955!3d29.8670879!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eb3650bfe37a7%3A0xa9d19b15af050467!2sIndian%20Institute%20Of%20Technology%E2%80%93Roorkee%20(IIT%E2%80%93Roorkee)!5e0!3m2!1sen!2sin!4v1665749312496!5m2!1sen!2sin"
                    style={{ width: "100%" }}
                    allowfullscreen=""
                    loading="lazy"
                    defer
                    referrerpolicy="no-referrer-when-downgrade"
                    title="Google Map location for CogXR Labs"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Element>
  );
}

export default FooterComponent;
