import React from "react";
import { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import "@fontsource/catamaran";
import "./Hero.css";
import BackgroundVideoWEBM from "../../assets/Intro bg.webm";
import BackgroundVideoMP4 from "../../assets/Intro bg.mp4";
import poster from "../../assets/poster.webp";

function HeroComponent() {
  const bgVideo = useRef(null);
  useEffect(() => {
    bgVideo.current.play();
  }, []);
  return (
    <>
      <a class="anchor" id="home"></a>
      <section className="text-center bg-image" id="hero-container">
        <div id="video-wrapper">
          <video
            id="bg-video"
            preload="auto"
            ref={bgVideo}
            autoplay
            loop
            muted
            playsinline
            poster={poster}
          >
            <source src={BackgroundVideoWEBM} type="video/webm"></source>
            <source src={BackgroundVideoMP4} type="video/mp4"></source>
          </video>
        </div>

        <Container id="hero-content">
          <div className="row flex-lg-row flex-md-column align-items-center g-1 py-5">
            <div className="col-lg-6 col-xl-7 d-flex flex-column">
              <div className="cxl-logo">
                <h1>
                  C<span className="hero-span">x</span>L
                </h1>
              </div>
              <div className="hero-text text-white text-start">
                <h2 className="pt-5">
                  Building Healthcare 4.0
                  <br />
                  step-by-step
                </h2>
              </div>
              {/* <button type="button" id="exp-more" class="btn btn-light my-4">
                Explore More<i class="fa-solid fa-arrow-right px-1"></i>
              </button> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default HeroComponent;
