import NavbarComponent from "./components/Navbar/Navbar";
import HeroComponent from "./components/Hero/Hero";
import ProductComponent from "./components/Products/Products";
import TeamComponent from "./components/Team/Team";
import FooterComponent from "./components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/montserrat";
import "./App.css";

function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <HeroComponent />
      <ProductComponent />
      <TeamComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
