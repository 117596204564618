import { useEffect, useRef, React } from "react";
import Container from "react-bootstrap/Container";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "@fontsource/catamaran";
import "./Team.css";

function TeamComponent() {
  const TeamRef = useRef();
  useEffect(() => {
    const tlOne = gsap.timeline({
      delay: 0.5,
      scrollTrigger: {
        trigger: TeamRef.current,
        start: "center bottom", // position [trigger scroller]
        end: TeamRef.current.clientWidth + " center", // position [trigger scroller]
        onLeave: function (self) {
          self.disable();
          self.animation.progress(1);
        },
      },
    });
    tlOne.fromTo(".team-up", { yPercent: 20 }, { yPercent: 0, duration: 1 });
  }, []);

  const teamMembers = [
    {
      fName: "Anmol",
      lName: "Gupta",
      designation: "Co-founder & CEO",
      quali: "PhD, IIT Roorke & University of Groningen,",
      quali1: "M.Tech, NIT Hamirpur | B.Tech NIT Uttarakhand",
      teamem: require("../../assets/anmol.webp"),
      instagram: "",
      facebook: "",
      linkedin: "https://www.linkedin.com/in/anmolscd/",
    },
    {
      fName: "Vishal",
      lName: "Pandey",
      designation: "Co-founder & CTO",
      quali: "PhD, IIT Roorkee | Ex-SRF, DRDO",
      quali1: "M.Tech, BITS Pilani | B.Tech, DU",
      teamem: require("../../assets/visha_original.webp"),
      instagram: "",
      facebook: "",
      linkedin: " https://www.linkedin.com/in/vmyth/",
    },
    {
      fName: "Prof. Partha",
      lName: "Pratim Roy",
      designation: "Co-founder & Mentor",
      quali:
        "Ass. Professor, CSE, IIT Roorke | Director Farmicon | Post Doc in France & Canada",
      quali1: "",
      teamem: require("../../assets/partha.webp"),
      instagram: "",
      facebook: "",
      linkedin: "https://www.linkedin.com/in/dr-partha-pratim-roy-ab1144238/",
    },
  ];

  return (
    <>
      <a class="anchor" id="who-we-are"></a>
      <section className="team text-white" ref={TeamRef}>
        <Container>
          <h2 className="heading-title text-uppercase font-weight-lighter mt-5 pt-5 text-white">
            Meet our team
          </h2>
        </Container>
        <Container className="card-list-container team-up">
          <div className="card-list row">
            {teamMembers.map((member, index) => {
              return (
                <div
                  className="card align-items-center p-2 col-6 col-md-4 col-lg-4"
                  key={index}
                  id="card"
                >
                  <div id="circle">
                    <img
                      class="circle-image"
                      src={member.teamem}
                      alt="Team-members"
                    />
                  </div>
                  <div className="profile-info">
                    <p className="name">
                      {member.fName} {member.lName}
                    </p>
                    <p id="designation">{member.designation}</p>
                    <p className="quali">{member.quali}</p>
                    <p className="quali">{member.quali1}</p>
                  </div>
                  <div className="social-media-icons">
                    {/* <a href={member.facebook} className="social-links p-1">
                      <i className="fa-brands fa-facebook" id="facebook"></i>
                    </a>
                    <a href={member.instagram} className="social-links p-1">
                      <i className="fa-brands fa-instagram" id="instagram"></i>
                    </a> */}
                    <a href={member.linkedin} className="social-links p-1">
                      <i className="fa-brands fa-linkedin" id="linkedin"></i>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </section>
    </>
  );
}

export default TeamComponent;
